import React, { useState, useEffect, useContext } from "react";
import { AppContext } from "../UtilityFunctions/AppContext.js";
import { AiOutlineCheckCircle } from "react-icons/ai";
import { FiCopy, FiCheck, FiSquare, FiCheckSquare, FiSend, FiShare } from "react-icons/fi";
import { IoClose, IoCopyOutline, IoLink } from "react-icons/io5";
import Switch from "@mui/material/Switch";

import { validateEmail } from "../UtilityFunctions/ValidateInputs.js";
import ModalWrapper from "./ModalWrapper.js";
import s from "../../styleModules/modalStyles.module.css";
import { useAxiosLimited } from "../UtilityFunctions/axiosRetry.js";
import { obfuscate, deobfuscate } from "../UtilityFunctions/DataUtility.js";
import Select from "react-select";
import circles from "../../images/circles.png";
import { number } from "prop-types";
import axios from "axios";

const url = process.env.REACT_APP_BACKEND_STATIC_URL;

export function ShareModal({ show, onHide, instage_id, OnReportSubmitted, tenantId }) {
	const { axiosLimitedGet, axiosLimitedPost, axiosLimitedPut, axiosLimitedPatch, axiosLimitedDelete } = useAxiosLimited();
	const { localUser, resumeAssistAccess, setupData, resumeData, performTranslation, userLanguage } = useContext(AppContext);

	const [showCopyIcon, setShowCopyIcon] = useState(true);
	const [showSendButton, setShowSendButton] = useState(false);
	const [reportSubmitted, setReportSubmitted] = useState(false);
	const [reportInstageId, setReportInstageId] = useState();
	const [inviteButtonText, setInviteButtonText] = useState("Invite");

	const [real_session_id, setReal_session_id] = useState("");

	const [emailList, setEmailList] = useState([]);
	const [inputValue, setInputValue] = useState("");
	const [inviteMessage, setInviteMessage] = useState("");
	const [hasInputedEmails, setHasInputedEmails] = useState(true);

	const [textHasBeenRendered, setTextHasBeenRendered] = useState(false);
	const [renderState, setRenderState] = useState(false);

	// Static Translation
	const LocalLanguageDict = {
		ShareThisPage: "Share this page",
		AnyoneWithLinkCanSeeThisPage: "Anyone with link can see this page.",
		CopiedLinkToClipboard: "Copied link to clipboard!",
		ShareReport: "Share Report",
		ShareInStageSimulation: "Share InStage Simulation",
		CopyLink: "Copy link",
		AnyoneWithLinkCanView: "Anyone with link can view.",
		NoEmailsInputed: "No emails have been inputed yet. Press 'Enter' to add them to the list.",
		InviteEmails: "Enter emails to invite others to try your simulation"
	};

	const [pageText, setPageText] = useState({
		...LocalLanguageDict
	});

	useEffect(() => {
		const translateText = async () => {
			await performTranslation(pageText, setPageText);
			setRenderState(!renderState);
		};

		translateText();
	}, [userLanguage]); // Depend on userLanguage to trigger re-translation

	useEffect(() => {
		setTextHasBeenRendered(true);
	}, [renderState]);

	// const [link, setLink] = useState("");
	const locationHref = window.location.href;

	const copyButtonRef = React.useRef(null);

	let link;
	const isReport = window.location.href.includes("report");
	React.useEffect(() => {
		if (emailList.length === 0) {
			setShowSendButton(false);
		} else {
			setShowSendButton(true);
		}
	}, [emailList]);

	const loadSubmission = async (session_id) => {
		// console.log("loadSubmission session_id: ", session_id);
		// if real_session_id contains any non-integer values, remove them so that it can be parsed as an integer
		try {
			const real_session_id = session_id.replace(/\D/g, "");
			if (typeof real_session_id === "number") {
				const result = await axiosLimitedGet(`${url}/api/sessionData/session/submitted`, 1, { params: { session_id: real_session_id } });
				const { submitted, instage_id } = result.data;
				console.log(result.data);
				return { submitted, instage_id };
			} else {
				return { submitted: false, instage_id: undefined };
			}
		} catch (error) {
			if (error.response) {
				console.error(error.response);
			}
			return { submitted: false, instage_id: undefined };
		}
	};

	const changeSubmission = async (session_id, status) => {
		console.log("changeSubmission session_id: ", session_id);
		const real_session_id = session_id.replace(/\D/g, "");
		const result = await axiosLimitedPost(`${url}/api/sessionData/session/submitted`, { session_id: real_session_id, status }, 1);
		// console.log("changeSubmission result: ", result)
		// console.log(result.data);

		return result.data;
	};
	const handleInputChange = (inputValue, actionMeta) => {
		if (actionMeta.action === "input-change") {
			setInputValue(inputValue); // update the inputValue state regardless of whether it's a valid email
		}
		if (inputValue !== "") {
			setHasInputedEmails(false);
		}
	};

	const handleKeyDown = (event) => {
		if (!event.target.value || (event.key !== "Enter" && event.key !== "," && event.key !== " ")) {
			return;
		}
		// Split the input value by comma or space
		const emails = event.target.value.split(/[\s,]+/);
		// Filter out invalid emails
		const validEmails = emails.filter((email) => validateEmail(email));
		// Add the valid emails to the emailList
		if (validEmails.length > 0) {
			setEmailList([...emailList, ...validEmails.map((email) => ({ value: email, label: email }))]);
			setInputValue("");
			setHasInputedEmails(true);
		} else {
			setHasInputedEmails(false);
		}
	};

	const sendInviteToSimEmail = async (theEmail, theLink) => {
		const emailValue = theEmail.value;
		const body = { email: emailValue, link: theLink };
		console.log(emailValue, theLink);
		try {
			const response = await axiosLimitedPost(`${url}/api/users/sendInviteToSimEmail`, body, 1, {}, 30000);
			console.log(response.data);
		} catch (error) {
			console.error(error);
		}
	};

	const changeSubmittedAssignment = async () => {
		try {
			const sessionIdPart = real_session_id.split("i_")[1] || real_session_id;
			console.log("session_id", sessionIdPart);
			const session = await axiosLimitedGet(`${url}/api/users/GetSessionByTheSessionId`, 1, {
				params: {
					sessionId: sessionIdPart
				}
			});
			console.log("session", session);
			const { simulation_fk } = session.data.result[0];
			console.log("real_session_id", sessionIdPart);
			console.log("instage_id", instage_id);
			console.log("simulation_fk", simulation_fk);

			try {
				await axiosLimitedPatch(
					`${url}/api/saved/UpdateSubmittedAssignmentReportLink`,
					{ instageId: instage_id, session_id: sessionIdPart, simulationFK: simulation_fk },
					1
				);
			} catch (error) {
				console.error(error);
			}
			// await axiosLimitedPatch(
			// 	`${url}/api/users/UpdateSubmittedAssignmentReportLink`,
			// 	{ instage_id: instage_id, session_id: real_session_id, simulation_fk: simulation_fk },
			// 	1
			// );
			console.log("UpdateSubmittedAssignmentReportLink response");
		} catch (error) {
			console.error(error);
		}
	};

	function handleInviteClick() {
		if (inviteButtonText === "Invite") {
			sendInviteEmails();
			setInviteButtonText("Sent");
			// Update the inviteMessage state
			setInviteMessage("Invite sent to: " + emailList.map((email) => email.value).join(", "));
			setTimeout(() => {
				setInviteButtonText("Invite");
				setInputValue("");
				setEmailList([]);
				setInviteMessage(""); // Clear the inviteMessage after 2 seconds
			}, 2000);
		}
	}

	function sendInviteEmails() {
		emailList.forEach((email) => {
			sendInviteToSimEmail(email, link);
		});
	}

	// Helper function to check if the path is invalid
	function isInvalidPath() {
		const urlPath = window.location.pathname.split("/");
		const lastSegment = urlPath.filter(Boolean).pop();
		const disallowedPaths = ["preview", "practice"];

		return disallowedPaths.includes(lastSegment);
	}

	if (tenantId && tenantId !== "" && !isReport && !isInvalidPath()) {
		if (resumeAssistAccess && setupData?.setup_type === "interview" && localUser) {
			link = `${window.location.origin}/share${window.location.pathname}/accId-${tenantId}?user_id=${localUser.id}`;
		} else {
			link = `${window.location.origin}/share${window.location.pathname}/accId-${tenantId}`;
		}
	} else if (window.location.href.includes("/super-admin")) {
		const tempLink = window.location.href.replace("/super-admin", "");
		const temp_real_session_id = tempLink.split("report/")[1];
		const obfuscated_session_id = obfuscate(temp_real_session_id);
		if (obfuscated_session_id !== "") {
			link = tempLink.replace(temp_real_session_id, obfuscated_session_id);
		} else {
			link = tempLink;
		}
	} else {
		const obfuscated_session_id = obfuscate(`share${real_session_id}`);
		link = `${window.location.origin}/share-report/${obfuscated_session_id} `;

		// link = window.location.href;
	}

	useEffect(() => {
		if (real_session_id !== "") {
			loadSubmission(real_session_id).then((result) => {
				setReportSubmitted(result.submitted);
				setReportInstageId(result.instage_id);
			});
		}
	}, [real_session_id]);

	useEffect(() => {
		if (locationHref.includes("report")) {
			let temp_real_session_id = locationHref.split("report/")[1].split("?")[0];
			const deobfuscated_session_id = deobfuscate(temp_real_session_id);
			if (deobfuscated_session_id !== "") {
				temp_real_session_id = deobfuscated_session_id;
			}
			setReal_session_id(temp_real_session_id);
		}
	}, [locationHref]);

	useEffect(() => {
		const handleAutoSubmit = async () => {
			if (isReport && show) {
				const status = await changeSubmission(real_session_id, true);
				setReportSubmitted(status);
				OnReportSubmitted(status);
				changeSubmittedAssignment();
				saveLinkToDb(status);
			}
		};

		handleAutoSubmit();
	}, [isReport, show]);

	function copyClick() {
		setShowCopyIcon(false);
		navigator.clipboard.writeText(link);

		setTimeout(() => {
			setShowCopyIcon(true);
		}, 1000);
	}
	const saveLinkToDb = async (isSubmitted) => {
		try {
			const sessionIdPart = real_session_id.split("i_")[1] || real_session_id;
			await axiosLimitedPatch(
				`${url}/api/sessionData/session`,
				{
					session_id: sessionIdPart,
					field: "report_link",
					value: isSubmitted ? link : " "
				},
				1
			);
			console.log("Link saved to database:", isSubmitted ? link : "empty");
		} catch (error) {
			console.error("Error saving link to database:", error);
		}
	};

	if (!isReport) {
		return (
			<>
				{show && (
					<ModalWrapper closeModal={() => onHide()} maxWidth="400px">
						<div className={`${s.modal} ${s.modalMinWidth}`}>
							<img src={circles} alt="Circles" className={s.modalCircles} />
							<div className={s.modalHeaderIconContainer}>
								<FiShare className={s.modalHeaderIcon} />
							</div>
							<IoClose className={s.modalCloseIcon} title="Close icon" onClick={() => onHide()} />

							<div className={s.modalTextContainer}>
								<h1 className={s.modalTitle}>{isReport ? pageText.ShareReport : pageText.ShareInStageSimulation}</h1>
								<p>{pageText.AnyoneWithLinkCanSeeThisPage}</p>
							</div>
							{!hasInputedEmails && <p style={{ color: "red" }}>{pageText.NoEmailsInputed}</p>}
							<div>
								{/* <input className={s.textbox} type="email" placeholder="Invite others to try your simulation"></input> */}
								<Select
									components={{ DropdownIndicator: null }}
									inputValue={inputValue} // use the inputValue state
									isClearable
									isMulti
									onChange={setEmailList}
									onKeyDown={handleKeyDown}
									onInputChange={handleInputChange}
									placeholder={pageText.InviteEmails}
									value={emailList}
								/>
							</div>

							{inviteMessage && <p>{inviteMessage}</p>}

							<div>
								<div className={s.modalUrlLinkSection}>
									{/* {showCopyIcon && (
										<input className={s.modalUrlLinkInput} defaultValue={link} />
									)} */}
									{!showCopyIcon && <p className={s.modalCopyNote}>{pageText.CopiedLinkToClipboard}</p>}

									{showCopyIcon && (
										<button type="button" className={s.modalCopyButton} onClick={copyClick}>
											<IoLink className={s.modalLinkIcon} />
											{pageText.CopyLink}
										</button>
									)}
									<button
										type="button"
										className={`${s.modalCopyButton} ${!showSendButton ? s.modalCopyButtonDisabled : ""}`}
										disabled={!showSendButton}
										onClick={showSendButton ? handleInviteClick : null}
									>
										<FiSend className={s.modalLinkIcon} />
										{inviteButtonText}
									</button>
								</div>
							</div>
						</div>
					</ModalWrapper>
				)}
			</>
		);
	}
	return (
		<>
			{show && (
				<ModalWrapper closeModal={() => onHide()} maxWidth="400px">
					<div className={`${s.modal} ${s.modalMinWidth}`}>
						<img src={circles} alt="Circles" className={s.modalCircles} />
						<div className={s.modalHeaderIconContainer}>
							<FiShare className={s.modalHeaderIcon} />
						</div>
						<IoClose className={s.modalCloseIcon} title="Close icon" onClick={() => onHide()} />

						<div className={s.modalTextContainer}>
							<h1 className={s.modalTitle}>
								{pageText.ShareThisPage}
								<Switch
									checked={reportSubmitted}
									onChange={async () => {
										const status = await changeSubmission(real_session_id, !reportSubmitted);
										setReportSubmitted(status);
										OnReportSubmitted(status);
										changeSubmittedAssignment();
										saveLinkToDb(status);
										console.log("status", status);
									}}
									inputProps={{ "aria-label": "controlled" }}
								/>
							</h1>
							<p>{pageText.AnyoneWithLinkCanView}</p>
							<>
								<p
									style={{ display: "flex", justifyContent: "center", alignItems: "center", margin: "0" }}
									className={s.modalCopyNote}
								>
									{reportInstageId === instage_id && <></>}
								</p>
							</>
						</div>

						<div>
							{reportSubmitted && (
								<div className={s.modalUrlLinkSection}>
									{showCopyIcon && (
										<button
											type="button"
											className={s.modalCopyButtonSmall}
											onClick={() => {
												copyClick();
											}}
											aria-label="Copy link"
										>
											<FiCopy className={s.copyIcon} />
										</button>
									)}
									{!showCopyIcon && (
										<button type="button" className={s.modalCopyButtonSmall} disabled aria-label="Copy confirmation">
											<FiCheck className={s.copyIcon} />
										</button>
									)}
									{showCopyIcon && <input className={s.modalInput} style={{ margin: "0" }} defaultValue={link} />}
									{!showCopyIcon && (
										<input
											className={s.modalInput}
											style={{ margin: "0" }}
											defaultValue={pageText.CopiedLinkToClipboard}
											disabled
										/>
									)}
								</div>
							)}
						</div>
					</div>
				</ModalWrapper>
			)}
		</>
	);
}

export default ShareModal;
